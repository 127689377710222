<template>
  <el-container>
      <el-main>
        <div>
            <el-button type="primary" size="small" @click="handleDialog(0,null)">+ 添加收银账号</el-button>
        </div>
        <!-- 表格 -->
        <el-table fit :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" class="table">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="cashier_name" label="姓名" align="center"></el-table-column>
            <el-table-column prop="cashier_account" label="登录账号" align="center"></el-table-column>
            <el-table-column prop="create_time" label="添加时间" align="center">
                <template slot-scope="scope">{{ getDateformat(scope.row.create_time) }}</template>
            </el-table-column>
            <el-table-column prop="state" label="是否启用" align="center">
                 <template slot-scope="scope">
                    <el-switch  v-model="scope.row.state" @change="changeSwitch(scope.row)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="operation">
                        <el-link type="primary" style="margin-right: 30px" :underline="false" @click="handleDialog(1,scope.row)">编辑</el-link>
                        <el-link type="primary" :underline="false" @click="deleteData(scope.row.id)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>

        <el-dialog :title="(dialogType==0?'添加':'编辑')+'收银账号'" :visible.sync="dialogVisible" width="40%"
        @closed="closeDialog">
            <el-form ref="form" :model="form" label-width="120px" :rules="rules">
                <el-form-item label="姓名：" prop="cashier_name">
                    <el-input v-model="form.cashier_name" class="inputClass"></el-input>
                </el-form-item>
                <el-form-item label="登录账号：" prop="cashier_account">
                    <el-input v-model="form.cashier_account" class="inputClass"></el-input>
                </el-form-item>
                <el-form-item label="登录密码：" prop="cashier_password" :required="dialogType==0">
                    <el-input v-model="form.cashier_password" class="inputClass"></el-input>
                </el-form-item>
                <el-form-item label="确认密码：" prop="cashier_repassword" :required="dialogType==0">
                    <el-input v-model="form.cashier_repassword" class="inputClass"></el-input>
                </el-form-item>
                <el-form-item label="是否启用：" required>
                     <el-switch v-model="form.state"></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmDialog">确 定</el-button>
            </span>
        </el-dialog>
      </el-main>
  </el-container>
</template>

<script>
import Paging from '@/components/paging'
import { getDateformat } from '@/util/getDate'
  export default {
    components: {
        Paging
    },
    data() {
        return {
            total: 0,
            page: 1,
            pageNum: 10,
            list:[],
            dialogVisible:false,
            form:{
                cashier_name:null,
                cashier_account:null,
                cashier_password:null,
                cashier_repassword:null,
                state:true
            },
            dialogType:0,//0--添加 1--编辑
            rules:{
                cashier_name: [
                    { required: true, message: '请填写姓名', trigger: 'blur' }
                ],
                cashier_account: [
                    { required: true, message: '请填写登录账号', trigger: 'blur' }
                ],
                cashier_password: [
                    { validator: (rule, value, callback) => {
                        if (!value && this.dialogType==0) {
                            callback(new Error('请填写密码'));
                        }else{
                            callback();
                        }
                    }, trigger: 'blur' }
                ],
                cashier_repassword: [
                   { validator: (rule, value, callback) => {
                        if (!value && this.dialogType==0) {
                            callback(new Error('请再次填写密码'));
                        }else if (value !== this.form.cashier_password) {
                            callback(new Error('两次输入密码不一致'))
                        }else{
                            callback();
                        }
                    }, trigger: 'blur' }
                ]
            }
        };
    },
    created() {
        this.getList()
    },
    methods : {
        // 分页操作
        updateData(val, status) {
            if (status == 0) {
                this.pageNum = val
            } else {
                this.page = val
            }
            // 重新获取数据
            this.getList()
        },
        // 获取列表
        getList(){
            this.$axios.post(this.$api.cashier.CashierUsersList, {
                page: this.page,
                rows: this.pageNum
            })
            .then((res) => {
                if (res.code == 0) {
                    let list = res.result.list
                    list.forEach(item=>{
                        item.state = item.state==1
                    })
                    this.list = list
                    this.total = res.result.total_number
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        // 关闭弹窗
        closeDialog(){
            this.$refs.form.resetFields();
        },
        // 添加账号
        confirmDialog(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let form = this.form
                    form.state = form.state?1:0
                    let api = this.dialogType==0?this.$api.cashier.CashierUsersAdd:this.$api.cashier.CashierUsersEdit
                    this.$axios.post(api,form)
                    .then((res) => {
                        if (res.code == 0) {
                            let msg = this.dialogType==0?'添加':'编辑'
                            this.$message.success(msg+'账号成功')
                            this.dialogVisible = false
                            this.getList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }
            });

        },
        // 处理时间
        getDateformat(val){
            return getDateformat(val)
        },
        //
        handleDialog(dialogType,item){
            this.dialogType = dialogType
            this.dialogVisible = true
            if(dialogType==1){
                this.form = {
                    id:item.id,
                    cashier_name:item.cashier_name,
                    cashier_account:item.cashier_account,
                    cashier_password:null,
                    cashier_repassword:null,
                    state:item.state
                }
            }
        },
        changeSwitch(item){
            let form = item
            form.state = form.state?1:0
            this.$axios.post(this.$api.cashier.CashierUsersEdit,form)
            .then((res) => {
                if (res.code == 0) {
                    let msg = form.state==1?'启用':'禁用'
                    this.$message.success('账号'+msg+'成功')
                    this.getList()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        deleteData(id){
            this.$confirm('确认要将此账号删除吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        })
        .then(() => {
            this.$axios.post(this.$api.cashier.CashierUsersDel, {
                id: id
            })
            .then((res) => {
                if (res.code == 0) {
                    this.$message.success('删除成功')
                    this.getList()
                } else {
                    this.$message.error(res.msg)
                }
            })
        })
        .catch((err) => {})
        }
    }
  };
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  display: flex;
  flex-direction: column;
}
.table{
    margin-top:15px;
    flex: 1;
}
.inputClass{
    width: 90%;
}

.el-form-item {
    margin-bottom: 22px;
}
</style>
